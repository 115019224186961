<!-- 家长指南 -->
<template>
  <div class='compass-container'>
    <img class="banner" src="../../assets/images/banner4.png" alt="">
    <div class="container">
        <h1>家长指南</h1>
        <div class="info">
            <img src="../../assets/images/img9.png" alt="">
        </div>
    </div>
  </div>
</template>

<script>

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {

    };
  },
  //方法集合
  methods: {

  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {

  },
}
</script>
<style lang='less' scoped>
.compass-container {
  margin-bottom: 187px;
  .banner {
    width: 100%;
  }

  .container {
    margin-top: 100px;
    h1 {
      font-weight: 400;
      font-size: 42px;
      color: #333333;
      text-align: center;
    }
    .info{
      margin-top: 100px;
    }
  }
}
</style>